import { QuoteType, Risk } from '@aventus/platform';
import { IntelligentQuoteQuestion } from '@aventus/platform';
import { getFromForm } from '@aventus/formio';
import moment from 'moment';

export const roadsideIsHiddenOracle = (
  question?: IntelligentQuoteQuestion,
  risk?: Risk,
  quoteType?: QuoteType,
  updateRisk?: (
    riskAddress: string,
    value: any,
    isCollection: boolean,
    isValid?: boolean
  ) => void
) => {
  if (question?.referenceID === 'stella_q_roadside_assistance' && risk) {
    const maxYears = 15;
    const isNewBusiness = quoteType === 'NewBusiness';
    const hasRoadside = question.questionType === 'BoxBoolean' ? getFromForm(risk, 'addon.roadsideAssistance') === true : getFromForm(risk, 'addon.tieredRoadsideAssistance.selected') === true;
    const vehicleYear: number = getFromForm(risk, 'vehicle.specification.year');
    const currentYear = moment().year();
    const difference = currentYear - vehicleYear;

    if (difference <= maxYears) return false;

    if (isNewBusiness || (!isNewBusiness && !hasRoadside)) {

      const newValue = question.questionType === 'BoxBoolean' ? false : { selected: false, level: null};

      updateRisk && hasRoadside && updateRisk(question.riskPath, newValue, false);

      return true;
    }
  }
  return false;
};
